.welcome_placeholder {
  padding: 30px 20px;
  line-height: 1.5;
  font-weight: 700;
  color: white;
}

.welcome_placeholder > div {
  position: absolute;
  bottom: 0;
  left: 5px;
}
.welcome_placeholder p {
  background: #000000;
}
.welcome_placeholder > div > div:nth-child(3) {
  padding-left: 10px;
  justify-content: flex-start;
  max-width: 306px;
  height: 48px;
  gap: 10px;
  margin: 0 5px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  background-color: black;
  cursor: pointer;
}

.welcome_placeholder > div > div::nth-child(3) > img {
  margin-left: 10px;
  border-radius: 50%;
  background-color: white;
}

.welcome_placeholder > div > div::nth-child(3) > p {
  padding-left: 10px;
}
.welcome_placeholder > div > div:nth-child(5) {
  padding-left: 10px;
  justify-content: flex-start;
  max-width: 306px;
  gap: 10px;
  height: 48px;
  margin: 0 5px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  background-color: black;
  cursor: pointer;
}

.welcome_placeholder > div > div:nth-child(2) {
  justify-content: flex-start;
  max-width: 306px;
  height: 48px;
  margin: 0 5px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  background-color: black;
  cursor: pointer;
}

.welcome_placeholder > div > div:nth-child(2) > img {
  margin-left: 10px;
  border-radius: 50%;
  background-color: white;
}

.welcome_placeholder > div > div:nth-child(2) > p {
  padding-left: 10px;
}
.welcome_placeholder > div > div:nth-child(4) {
  padding-left: 10px;
  justify-content: flex-start;
  max-width: 306px;
  height: 48px;
  margin: 0 5px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  background-color: black;
  cursor: pointer;
}

.welcome_placeholder > div > div:nth-child(4) > img {
  margin-left: 10px;
  border-radius: 50%;
  background-color: white;
}

.welcome_placeholder > div > div:nth-child(4) > p {
  padding-left: 10px;
}
.welcome_placeholder > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome_placeholder > div > div:last-of-type > img {
  padding: 5px;
  cursor: pointer;
}
.welcome_placeholder > div > div:last-of-type {
  display: block;
}
.logo_normal {
  position: relative;
  background: white;
  justify-content: flex-start;
  max-width: 306px;
  height: auto;
  margin: 0 5px;
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .welcome_placeholder {
    text-align: center;
  }

  .welcome_placeholder > div > div:nth-of-type(3),
  .welcome_placeholder > div > div:nth-of-type(2),
  .welcome_placeholder > div > div:nth-of-type(4) {
    display: none;
  }
  .welcome_placeholder > div {
    position: relative;
  }
}

@media only screen and (max-width: 576px) {
  .welcome_placeholder {
    padding: 20px 15px 10px;
  }
}
.inf_normal {
  border-radius: 50% !important;
  background-color: #fff !important;
}
span.logotxt {
  font-size: 9.5px;
  color: #2a7da7;
  display: block;
  background: white;
  clear: both;
  padding: 5px 0;
  margin-bottom: 10px;
  width: 99%;
}
.logoimgtxt {
  background: white;
  clear: both;
  padding: 5px 0;
  margin-bottom: 0px;
  width: 99%;
}
.logoimgtxt a,
.logoimgtxt span {
  float: left;
  display: block;
  clear: both;
}
